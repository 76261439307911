import appConfig from 'configs/app.config'  
const { BlobServiceClient } = require("@azure/storage-blob");
const { AZURE_containername,sasToken,storageAccountName } = appConfig

function fileNameAndExt(str){
  var file = str.split('/').pop();
  return file.substr(file.lastIndexOf('.')+1,file.length)
}

const createBlobInContainer = async (containerClient, file) => {
   //unique
   console.log("Entrando")
   const unique=Date.now() + Math.random();
   const fileext=fileNameAndExt(file.name);
   console.log(fileext)
   console.log("unique", unique)
   console.log("iMAGEN", file)

    // create blobClient for container
    const blobClient = containerClient.getBlockBlobClient("productupload_" + unique + "." + fileext);
  
    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
  
    // upload file
    await blobClient.uploadBrowserData(file, options);
    //await blobClient.setMetadata({UserName : 'UcontrolApp'});


    return blobClient.url;
  };


  const createBlobInContainerSS = async (containerClient, file) => {
    //unique
    console.log("Entrando")
    const unique=Date.now() + Math.random();
    const fileext=fileNameAndExt(file.name);
    console.log(fileext)
    console.log("unique", unique)
    console.log("iMAGEN", file)
 
     // create blobClient for container
     const blobClient = containerClient.getBlockBlobClient("file_" + unique + "." + fileext);
   
     // set mimetype as determined from browser with file upload control
     const options = { blobHTTPHeaders: { blobContentType: file.type } };
   
     // upload file
     await blobClient.uploadBrowserData(file, options);
     //await blobClient.setMetadata({UserName : 'UcontrolApp'});
 
 
     return blobClient.url;
   };

export async function uploadImage(file){

  console.log("archivo a subir",file)
    try{
        if (!file) return '';
  
        // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
        const blobService = new BlobServiceClient(
          `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
        );
        // get Container - full public read access
        const containerClient = blobService.getContainerClient(AZURE_containername);
    
        // upload file
        var result = await createBlobInContainer(containerClient, file);
      
        // get list of blobs in container
    
        return result;
    }catch{
return '';
    }

//     if (!AZURE_STORAGE_CONNECTION_STRING) {
//       throw Error('Azure Storage Connection string not found');}
  
//     // Create the BlobServiceClient object with connection string
//     const blobServiceClient = BlobServiceClient.fromConnectionString(AZURE_STORAGE_CONNECTION_STRING);
//     // Get a reference to a container
//   const containerClient = blobServiceClient.getContainerClient(AZURE_containername);
//     // Create a unique name for the blob
//     const blobName = file.name;
//     // Get a block blob client
//     const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    
//     // Upload data to the blob
//     try{
//       const uploadBlobResponse = await blockBlobClient.upload(file);
//       console.log(
//         `Blob was uploaded successfully. requestId: ${uploadBlobResponse.requestId}`
//       );
//       return { message: "ok", url: blockBlobClient.url }
//     }catch{
//       return{ message: "Error" };
//     }
}


export async function uploadFile(file){

  console.log("archivo a subir",file)
    try{
        if (!file) return '';
  
        // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
        const blobService = new BlobServiceClient(
          `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
        );
        // get Container - full public read access
        const containerClient = blobService.getContainerClient(AZURE_containername);
    
        // upload file
        var result = await createBlobInContainerSS(containerClient, file);
      
        // get list of blobs in container
    
        return result;
    }catch{
return '';
    }


}